
import { defineComponent, onMounted, ref, reactive } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Loading from "vue-loading-overlay";

interface editDetail {
  number: string;
  startDate: string;
  expiredDate: string;
  status: string;
  name: string;
  note: string;
}

// interface getOnly {
//   name: string;
// }

interface items {
  listStatus: any;
  errorNamaSekolah: string;
  errorNomorPks: string;
  errorStartDate: string;
  errorEndDate: string;
  errorStatus: string;
}

export default defineComponent({
  name: "Cabang",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading,
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    // const editDetailValidator = Yup.object().shape({
    //   number: Yup.string().required("Nomor Dokumen Wajib Diisi"),
    //   startDate: Yup.string().required("Tanggal Mulai Wajib Diisi"),
    // });

    const editDetail = reactive<editDetail>({
      name: "",
      number: "",
      startDate: "",
      expiredDate: "",
      status: "",
      note: "",
    });

    // const getOnly = reactive<getOnly>({
    //   name: "",
    // });

    let items = reactive<items>({
      listStatus: [{}],
      errorNamaSekolah: "",
      errorNomorPks: "",
      errorStartDate: "",
      errorEndDate: "",
      errorStatus: "",
    });

    function dateTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD");
      } else {
        return "";
      }
    }

    const getLegalStatus = () => {
      ApiService.getWithoutSlug("crmv2/main_legal/legal/legal/combo/status")
        .then(({ data }) => {
          items.listStatus = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const getIdDetailLegalitas = () => {
      ApiService.getWithoutSlug(
        "crmv2/main_legal/legal/legal/get_document_legal/" +
          route.params.idSekolah +
          "/" +
          route.params.idDokumen
      )
        .then((res) => {
          console.log(res.data.documentLegality.expiredDate);
          // getOnly.name = res.data.name;

          editDetail.name = res.data.name;
          editDetail.number = res.data.documentLegality.number;
          editDetail.startDate = dateTime(res.data.documentLegality.startDate);
          editDetail.expiredDate = dateTime(
            res.data.documentLegality.expiredDate
          );
          editDetail.note = res.data.documentLegality.note;
          editDetail.status = res.data.documentLegality.status;
        })
        .catch((e) => {
          toast.error(e.response.data.detail);
        });
    };

    const saveChanges1 = () => {
      if (
        editDetail.name === "" ||
        editDetail.number === "" ||
        editDetail.number == null ||
        editDetail.startDate === "" ||
        editDetail.expiredDate === "" ||
        editDetail.status === ""
      ) {
        if (editDetail.name === "") {
          items.errorNamaSekolah = "Nama Sekolah Wajib Diisi";
        }
        if (editDetail.number === "" || editDetail.number == null) {
          items.errorNomorPks = "Nomor PKS Wajib Diisi";
        }
        if (editDetail.startDate === "") {
          items.errorStartDate = "Start Date Wajib Diisi";
        }
        if (editDetail.expiredDate === "") {
          items.errorEndDate = "End Date Wajib Diisi";
        }
        if (editDetail.status === "") {
          items.errorStatus = "Status Wajib Diisi";
        }
        toast.error("Form belum lengkap");
        // items.errorfile = "File Dokumen Wajib Diisi";
      } else {
        // console.log("save2");
        items.errorNamaSekolah = "";
        items.errorNomorPks = "";
        items.errorStartDate = "";
        items.errorEndDate = "";
        items.errorStatus = "";
        isLoading.value = true;

        submitButton.value?.setAttribute("data-kt-indicator", "on");

        if (editDetail.startDate > editDetail.expiredDate) {
          console.log("errordate");
          toast.error("Tanggal Mulai tidak boleh lebih dari Tanggal Selesai");
          isLoading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
        } else {
          setTimeout(() => {
            // Send login request
            ApiService.putWithData(
              "crmv2/main_legal/legal/legal/" +
                route.params.idSekolah +
                "/" +
                route.params.idDokumen,
              editDetail
            )
              .then((res) => {
                toast.success("Berhasil Update Detail Legalitas");
                isLoading.value = false;
                router.push({
                  path: "/superadmin/legal/legalitas",
                });
              })
              .catch((e) => {
                toast.error(e.response.data.detail);
                isLoading.value = false;
              });
            submitButton.value?.removeAttribute("data-kt-indicator");
          }, 2000);
        }

        // console.log(editDetail);

        // dummy delay
      }
      // Activate loading indicator
    };

    onMounted(() => {
      getIdDetailLegalitas();
      getLegalStatus();
    });

    return {
      submitButton,
      saveChanges1,
      editDetail,
      getIdDetailLegalitas,
      getLegalStatus,
      items,
      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
